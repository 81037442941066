\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="장치명"
            name="deviceName"
            v-model="searchParam.deviceName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="장치번호"
            name="equipmentNo"
            v-model="searchParam.equipmentNo">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            label="관련공정"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="deviceMachine"
          title="장치 및 설비 목록"
          tableId="deviceMachine"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :merge="grid.merge"
          rowKey="psiDeviceEquipmentId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="등록" icon="add" @btnClicked="equipmentPopup" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'device-machine',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'equipmentNo' },
          { index: 1, colName: 'equipmentNo' },
          { index: 2, colName: 'equipmentNo' },
          { index: 3, colName: 'equipmentNo' },
          { index: 20, colName: 'equipmentNo' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:80px',
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '장치번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'deviceName',
            field: 'deviceName',
            label: '장치명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'devicePartName',
            field: 'devicePartName',
            label: '장치구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'inMaterials',
            field: 'inMaterials',
            label: '내용물',
            align: 'center',
            style: 'width:120px',
            type: 'html',
            sortable: true,
          },
          {
            name: 'volume',
            field: 'volume',
            label: '용량',
            align: 'center',
            style: 'width:150px',
            type: 'html',
            sortable: true,
          },
          {
            label: '압력(MPa)',
            align: 'center',
            child: [
            {
              name: 'pressOperation',
              field: 'pressOperation',
              label: '운전',
              align: 'center',
              style: 'width:60px',
              sortable: true,
            },
            {
              name: 'pressDesign',
              field: 'pressDesign',
              label: '설계',
              align: 'center',
              style: 'width:60px',
              sortable: true,
            },
            ]
          },
          {
            label: '온도(℃)',
            align: 'center',
            child: [
            {
              name: 'tempOperation',
              field: 'tempOperation',
              label: '운전',
              align: 'center',
              style: 'width:60px',
              sortable: true,
            },
            {
              name: 'tempDesign',
              field: 'tempDesign',
              label: '설계',
              align: 'center',
              style: 'width:60px',
              sortable: true,
            },
            ]
          },
          {
            label: '사용재질',
            align: 'center',
            child: [
            {
              name: 'materialMain',
              field: 'materialMain',
              label: '본체',
              align: 'center',
              style: 'width:120px',
              sortable: true,
            },
            {
              name: 'materialSubPart',
              field: 'materialSubPart',
              label: '부속품',
              align: 'center',
              style: 'width:120px',
              sortable: true,
            },
            {
              name: 'materialGasket',
              field: 'materialGasket',
              label: '개스킷',
              align: 'center',
              style: 'width:120px',
              sortable: true,
            },
            ]
          },
          {
            name: 'weldingEfficiency',
            field: 'weldingEfficiency',
            label: '용접효율',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'calculateThick',
            field: 'calculateThick',
            label: '계산두께(mm)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'corrosionAllowance',
            field: 'corrosionAllowance',
            label: '부식여유(mm)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'useThick',
            field: 'useThick',
            label: '사용두께(mm)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'afterHeatTreatYn',
            field: 'afterHeatTreatYn',
            label: '후열처리여부',
            align: 'center',
            sortable: true,
          },
          {
            name: 'nondestructPercent',
            field: 'nondestructPercent',
            label: '비파괴검사율(%)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'applicableLaws',
            field: 'applicableLaws',
            label: '적용법령',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
        ],
        data: [],
      },
      searchParam: {
        deviceName: '',
        plantCd: '',
        equipmentNo: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.pfi.device.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./deviceEquipmentDetail.vue"}`);
      this.popupOptions.title = '장치 및 설비 상세';
      this.popupOptions.param = {
        psiDeviceEquipmentId: result ? result.psiDeviceEquipmentId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
